import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user/user.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { ArrayService } from './array.service';
import { TranslationService } from './translation/translation.service';
import { OneSignalService } from './one-signal.service';
import { AbTestService } from '../ab-test/ab-test.service';

@Injectable({
  providedIn: 'root',
})
export class DataPreloaderService {

  /**
   * List of observables that need to be preloaded before app start
   */
  private _dataToPreload: Array<any> = [
    this._user.auth$.pipe(first()),
    this._translate.getUserTranslates$().pipe(first()),
    this._ab.loadABTest$('DEVBIT3793').pipe(first()),
    this._ab.loadABTest$('DEVBIT3367').pipe(first()),
    this._ab.loadABTest$('DEVBIT3486').pipe(first()),
  ];

  /**
   * Is data loaded
   */
  private _loaded = false;

  constructor(
    private _env: EnvironmentService,
    private _user: UserService,
    private _translate: TranslationService,
    private _language: LanguageService,
    private _array: ArrayService,
    private _oneSignal: OneSignalService,
    private _ab: AbTestService
  ) {
  }

  /**
   * Access to _loaded
   */
  get loaded(): boolean {
    return this._loaded;
  }

  /**
   * For main route resolving
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._env.env$.pipe(
      first(),
      tap((env) => {
        if (!this._language.current) {
          const params = this._array.routeToArray(state.url);
          const routeLang = params[0];
          this._language.changeLang(this._language.detectUserLang(routeLang, this._env.env.locale.short, this._env.env.languageList));
        }

      }),
      switchMap(() => forkJoin(this._dataToPreload)),
      tap(() => {
        this._loaded = true;
        this._oneSignal.handleId();
      }));
  }
}
